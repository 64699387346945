/* TODO: we need to get rid of these common styles */



//changing default theme colors

$theme-colors: (
  'dark': "#231D5D",
  'light': "#FFFFFF",
  'green':"#00BAB9",
  'purple':"#7054A1",
  'pink':"#ED4799",
  'pinkLight': "rgba(237, 71, 153, 0.12)"
);

body {
  background-color: rgb(23, 20, 61);
}

.login-main {
  background-color: rgb(23, 20, 61);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.getting-started {
  color: #046cfc;
  margin-left: 4px;
}

.getting-started:hover {
  cursor: pointer;
}

.help-text {
  margin-top: 4px;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.err-help-text {
  margin-top: 4px;
  color: red !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.login-main .login-inr {
  width: 420px;
  max-width: 90%;
  margin: 0 auto;

  .btn-fractal {
    width: 100%;
    background-color: #046cfc;
    font-family: "Righteous";
    font-weight: 400;
    justify-content: center;
    border-radius: 8px;
    height: 50px;
    margin-top: 20px;

    div:first-child {
      display: none;
    }
  }
}

.btn-otr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #242735;
  padding: 12px 20px;
  border: 1px solid #6b7185;
  border-radius: 8px;
  transition: 0.2s;
}

.btn-otr:not(:last-child) {
  margin-bottom: 20px;
}

.btn-otr:hover {
  border: 1px solid #a1a6b6;
}

.btn-img {
  width: 32px;
}

.btn-text {
  margin-left: 12px;
  color: white;
  font-family: "Righteous";
}

.btn-Ronin {
  background-color: #046cfc;
  border: 1px solid transparent;
}

.btn-meta {
  background-color: #046cfc;
  border: 1px solid transparent;
}

.btn-Ronin:hover {
  border: 1px solid transparent;
}

.form-otrrr {
  padding: 32px 20px;
  background-color: #282b39;
  border-radius: 8px;
  border: 1px solid #3a3f50;
}

.form-otrrr .input {
  border: 1px solid #3a3f50;
  background-color: #11131b;
  color: white;
  font-family: "Righteous";
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;
  transition: 0.2s;
}

.form-otrrr .input:focus {
  border: 1px solid #046cfc;
  outline: none;
}

.email-input {
  margin-bottom: 20px;
}

.form-otr2 .email-input {
  margin-bottom: 20px !important;
}

.user-help {
  margin-bottom: 2px;
  margin-top: 0px !important;
  padding: 0 4px 4px 4px;
}

.span-otr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 28px 0;
}

.Remember-otr {
  position: relative;
  top: 3px;
}

.Remember-otr .checkbox {
  width: 20px;
  height: 20px;
}

.Remember-otr .lable {
  color: white;
  font-family: "Righteous";
  margin-left: 8px;
  position: relative;
  top: -4px;
}

.forget {
  background-color: transparent;
  color: #046cfc;
  font-family: "Righteous";
  border: none;
}

.btn-custom {
  margin-top: 5px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #046cfc;
  color: white;
  text-align: center;
  font-family: "Righteous";
  border: none;
  width: 100%;
}

.btn-sign-in {
  margin-bottom: 20px;
}

.hidden1 {
  display: none;
}

.sign-up-main {
  margin-top: 180px;
  background-color: #242735;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.email-otr {
  margin-top: 180px;
  background-color: #17143d;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sign-up-main .sign-up-inr {
  background-color: #046cfc;
  padding: 40px 60px 50px 60px;
}

.email-otr .email-inr {
  background-color: #046cfc;
  padding: 40px 60px 10px 60px;
}

.header-main .heading {
  color: white;
  font-family: "Righteous";
  padding: 0 0 30px 0;
}

.btn-otr2 {
  display: flex;
  align-items: center;
  background-color: #242735;
  padding: 12px 20px;
  border: 1px solid #6b7185;
  border-radius: 8px;
  transition: 0.2s;
  color: white;
}

.btn-otr2:not(:last-child) {
  margin-bottom: 20px;
}

.btn-otr2:hover {
  border: 1px solid #a1a6b6;
}

.btn-img {
  width: 32px;
}

.btn-text {
  margin-left: 12px;
  color: white;
  font-family: "Righteous";
}

.form-otr2 {
  padding: 50px 15px;
  background-color: #282b39;
  border-radius: 8px;
  border: 1px solid #3a3f50;
  max-width: 420px;
}

.form-otr2 {
  padding: 20px 15px 50px 15px;
  background-color: #282b39;
  border-radius: 8px;
  border: 1px solid #3a3f50;
  max-width: 420px;
}

.credentials {
  color: white;
  font-family: "Righteous";
  margin-bottom: 15px;
}

.form-otr2 .input {
  border: 1px solid #3a3f50;
  background-color: #11131b;
  color: white;
  font-family: "Righteous";
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;
  transition: 0.2s;
}

.form-otr2 .input:focus {
  border: 1px solid #046cfc;
  outline: none;
}

.form-otr2 .email-input {
  margin-bottom: 20px;
}

.form-otr2 .span-otr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 28px 0;
}

.form-otr2 .span-otr-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.form-otr2 .Remember-otr {
  position: relative;
  top: 3px;
}

.form-otr2 .Remember-otr .checkbox {
  width: 20px;
  height: 20px;
}

.form-otr2 .Remember-otr .lable {
  color: white;
  font-family: "Righteous";
  margin-left: 8px;
  position: relative;
  top: -4px;
}

.form-otr2 .forget {
  background-color: transparent;
  color: #046cfc;
  font-family: "Righteous";
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.form-otr2 .btn-custom {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #046cfc;
  color: white;
  text-align: center;
  font-family: "Righteous";
  border: none;
  width: 100%;
  margin-top: 20px;
}

.form-otr2 .btn-sign-in {
  margin-bottom: 20px;
}

.modal-dialog-frm {
  width: 420px;
  max-width: 90%;
}

.modal-content-frm {
  background-color: transparent;
  border: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  display: block;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

.modal-body {
  width: 100% !important;
  padding: 0px !important;
  border-radius: 8px !important;
  background-color: #282b39;
}

.modal-content {
  max-width: 420px !important;
  padding: 0px !important;
  border-radius: 8px !important;
  background-color: #282b39;
  height: 100%;
  margin: auto;
}