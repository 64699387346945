.drawer-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.side-drawer {
  height: 100%;
  background: var(--primary-dark, #231d5d);
  box-shadow: 0px 12px 56px 0px rgba(17, 17, 33, 0.16);
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  z-index: 200;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(17, 17, 33, 0.3);
  backdrop-filter: blur(12px);
}

.cancelIcon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  background: #F2F2F7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconDark {
  background-color: rgba(255, 255, 255, 0.10);

}
