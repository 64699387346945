//common classes
.background-dark {
  background-image: url(../../assets//img/bg-dark.png);
}

.background-light {
  background-image: url(../../assets//img/bg-light.png);
}

.text-dark {
  color: var(--primary-dark, #231d5d) !important;
}

.dasboard-wrapper {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .nav-mobile {
    padding: 18px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(20px);
    z-index: 99;
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .edit-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;
    border: 2px solid #3a3f50;
    border-radius: 6px;
    margin-bottom: 24px;
  }

  .sidebar-dark {
    background: rgba(255, 255, 255, 0.04);
  }

  .left-sidebar {
    height: 100vh;
    padding: 40px;
    padding-right: 0;
    left: 0;
    top: 0;
    position: fixed;

    @media screen and (min-width: 768px) {
      width: 314px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .left-sidebar-mob {
    height: 100vh;
    padding: 40px;
    padding-right: 0;
  }

  .sidebar-dashboard {
    height: 100%;
    position: relative;
  }

  .edit-otr {
    display: flex;
    align-items: center;
  }

  .text-edit {
    font-size: 18px;
    color: white;
    font-family: "Righteous";
    margin-right: 12px;
  }

  .icon-otr .icon {
    width: 24px;
    cursor: pointer;
  }

  .show-btn {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    background-color: transparent;
    border: 2px solid #3a3f50;
    border-radius: 4px;
    margin-top: 24px;
  }

  .icon {
    margin-right: 12px;
  }

  .text {
    font-size: 16px;
    font-weight: 500;
    font-family: "Righteous";
    color: white;
  }

  .option-li {
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    margin: 40px 0;
  }

  .option-a {
    display: flex;
    align-items: center;
    background-color: transparent;

    border-radius: 6px;
    transition: 0.3s;

    &::hover {
      background-color: #3a3f50;
    }
  }

  .option-img {
    width: 25px;
    height: 25px;

    .img-light {
      color: #ffffff;
    }

    .img-dark {
      color: #000000;
    }
  }

  .option-text {
    // color: #231D5D;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 20px;
  }

  .disable-btn {
    opacity: 0.6;
    cursor: default;
  }

  .logout {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .option-header {
    margin-bottom: 3rem;
  }

  .content-wrapper {
    width: 100%;
    padding: 0 50px;
    margin-left: 314px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }

    @media (max-width: 768px) {
      padding-top: 74px;
    }

    @media (max-width: 520px) {
      padding: 0 20px;
      padding-top: 74px;
    }

    .nav-bar {
      padding: 36px 0;
      color: #231d5d;
      font-weight: 400;
      font-size: 40px;
      font-family: "BebasNeue";
    }

    .wallets {
      padding: 36px 0;

      @media (max-width: 520px) {
        margin-left: -20px;
        margin-right: -20px;
      }
    }

    .account-card-dark {
      background: rgba(255, 255, 255, 0.08);
      color: #fff;
    }

    .account-card-light {
      background: #fff;
    }

    .account-card {
      border-radius: 20px;
      box-shadow: 0px 12px 46px 0px rgba(17, 17, 33, 0.08);
      padding: 20px;
      max-width: 364px;
      width: 100%;
    }

    .heading-w {
      color: #231d5d;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .heading-w {
      font-family: "BebasNeue";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .message-box-light {
      background-color: #fff;
    }

    .message-box-dark {
      background-color: rgba(237, 71, 153, 0.12);
    }

    .fighters-dark {
      background: rgba(255, 255, 255, 0.08);
      color: #fff;
    }

    .fighters-light {
      background: #fff;
    }

    .fighters {
      max-width: 632px;
      width: 100%;
      min-height: 620px;
      border-radius: 20px;
      padding: 24px 20px;
      box-shadow: 0px 12px 46px 0px rgba(17, 17, 33, 0.08);

      @media (max-width: 520px) {
        border-radius: 0;
      }

      .top-fighter-Wrapper {
        max-width: 592px;
        width: 100%;
        height: 196px;
        border-radius: 20px;
        background: transparent;
        margin-top: 110px;
        position: relative;

        @media (min-width: 940px) {
          background: rgba(199, 93, 226, 0.08);
        }
      }

      .top-fighter-inner {
        position: absolute;
        width: 100%;
        top: -80px;
        display: flex;
        justify-content: space-between;
        padding: 0;

        @media (min-width: 940px) {
          padding: 0 66px;
        }
      }

      .fighters-card-wrapper {
        display: flex;
        width: 170px;
        flex-direction: column;
        align-items: center;

        @media (max-width: 768px) {
          width: 160px;
        }
      }

      .fighter-img {
        height: 170px;
        border-radius: 12px;

        @media (max-width: 768px) {
          height: 160px;
        }
      }

      h4 {
        color: var(--primary-green, #00bab9);
        text-align: center;
        font-family: "BebasNeue";
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.2px;
        margin-top: 6px;
      }

      p {
        color: var(--primary-purple, #7054a1);
        font-family: Inter, sans-serif;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.14px;
        margin-top: 8px;
      }
    }

    .gcoin-earning-badge {
      border-radius: 8px;
      background: linear-gradient(153deg, #ed4799 0%, #f06cad 100%);
      color: var(--primary-white, #fff);
      font-family: Inter, sans-serif;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 12px 0px 12px 14px;
      margin-top: 35px;
      row-gap: 14px;

      img {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
  }

  .heading-h3 {
    color: var(--primary-white, #fff);
    font-family: BebasNeue;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

//common classes

.text-label {
  color: var(--primary-dark, #231d5d);
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
}

.text-para {
  color: var(--primary-dark, #231d5d);
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
  word-break: break-word;
  opacity: 0.5;
}

.content-wrapper-x {
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 20px 40px 10px 40px;
}

.dasboard-wrapper .col-setting-otr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 20px;
  background: linear-gradient(159deg, #00bab9 0%, #1adddc 100%);
  padding: 28px 20px 28px 30px;
  margin-bottom: 30px;
}

.walletAside {
  max-width: 364px;
  width: 100%;
  height: 98px;
  border-radius: 20px;
  padding: 0 20px;
}

.walletAside-light {
  background: #fff;
}

.walletAside-dark {
  background: rgba(255, 255, 255, 0.08);
}

.walletAside p {
  color: #7054a1;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.walletAside h4 {
  color: #231d5d;
  font-family: BebasNeue;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.walletAside .gCoin-bg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff4de;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.dasboard-wrapper .set-btn {
  display: flex;
  align-items: center;
  padding: 20px 45px;
  background-color: var(--primary-pink, #ed4799);
  border-radius: 20px;
  border: none;
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 700;

  @media (max-width: 1080px) {
    margin-top: 30px;
  }
}

.dasboard-wrapper .toggleBtn {
  position: absolute;
  bottom: 80px;
  left: 0;
}

.dasboard-wrapper .logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  max-width: 272px;
  width: 100%;
  border-radius: 20px;
  border: none;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-right: 40px;
  background-color: var(--primary-pink, #ed4799);

  @media screen and (min-width: 768px) {
    max-width: 232px;
    background-color: transparent;
    justify-content: left;
    color: #231d5d;
  }
}

.dasboard-wrapper .set-text {
  font-size: 16px;
  font-weight: 600;
  font-family: "Righteous";
  color: white;
}

.dasboard-wrapper .heading-w {
  color: #231d5d;
  font-family: BebasNeue;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dasboard-wrapper .inner-box-wapper {
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}

.dasboard-wrapper .wb-heading {
  font-size: 24px;
  color: white;
  font-family: "Righteous";
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 4px;
}

.dasboard-wrapper .wp-des {
  color: lightgray;
  font-size: 14px;
  line-height: 20px;
  font-family: "Righteous";
  font-weight: 400;
}

.dasboard-wrapper .wp-inner-bottom {
  display: flex;
  align-items: center;
}

.dasboard-wrapper .btn-simple {
  color: lightgray;
  font-size: 14px;
  line-height: 20px;
  font-family: "Righteous";
  font-weight: 400;
  border: 2px solid #3a3f50;
  width: 49%;
}

.dasboard-wrapper .btn-simple:hover {
  color: white;
}

.btn-simple:focus {
  box-shadow: none;
}

.dasboard-wrapper .nested-box-email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 2px 2px 0 2px;
  border-color: #3a3f50;
  border-style: solid;
  padding: 16px;
}

.dasboard-wrapper .nb-des {
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
}

.dasboard-wrapper .icon-otr {
  display: flex;
  align-items: center;
}

.dasboard-wrapper .icon-2 {
  margin-left: 12px;
}

.spam-notification {
  color: var(--primary-white, #fff);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.dasboard-wrapper .col-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 6px 10px;
  width: fit-content;
  border: 2px solid #3a3f50;
  border-radius: 8px;
  height: 48px;
}

.dasboard-wrapper .box-1,
.box-2 {
  margin-bottom: 30px;
}

.dasboard-wrapper .box-icon-otr {
  margin-right: 1rem;
}

.dasboard-wrapper .col-inr {
  width: 102px;
}

.dasboard-wrapper .col-inr-iconWrapper {
  width: 102px;
  height: 102px;
  background-color: rgb(178, 178, 175);
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.assets-cardWrapper {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.dasboard-wrapper .box-icon {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.dasboard-wrapper .desc {
  color: var(--primary-dark, #231d5d);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 9px;
  letter-spacing: -0.28px;
}

.dasboard-wrapper .col-activity-inr {
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.dasboard-wrapper .activity-text {
  font-size: 24px;
  line-height: 36px;
  font-family: "Righteous";
  font-weight: 600;
  color: white;
}

.dasboard-wrapper .form-ot {
  display: flex;
  align-items: center;
}

.dasboard-wrapper .input {
  border: 1px solid #3a3f50;
  background-color: #11131b;
  color: white;
  font-family: "Righteous";
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;
  transition: 0.2s;
}

.dasboard-wrapper .input:focus {
  border: 1px solid #046cfc;
  outline: none;
}

.dasboard-wrapper .input:not(:last-child) {
  margin: 0 20px 0 24px;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  width: 80px;
  height: 36px;
  border-radius: 55px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.checkbox-label .ball {
  padding: 7px 12px;
  position: absolute;
  left: 3px;
  top: 3px;
  border-radius: 55px;
  transition: transform 0.2s linear;
  background: #ed4799;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(33px);
}

.toggleDarkBtn {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 41px;
}

.toggleDarkBtn input {
  opacity: 0;
  width: 0;
  height: 0;
}
