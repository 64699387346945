@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url("../fonts/AvenirNextLTPro-Bold.eot");
  src: url("../fonts/AvenirNextLTPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-Bold.otf") format("otf"),
    url("../fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold") format("svg"),
    url("../fonts/AvenirNextLTPro-Bold.ttf") format("truetype"),
    url("../fonts/AvenirNextLTPro-Bold.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "AvenirNextLTPro-It";
  src: url("../fonts/AvenirNextLTPro-It.eot");
  src: url("../fonts/AvenirNextLTPro-It.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-It.otf") format("otf"),
    url("../fonts/AvenirNextLTPro-It.svg#AvenirNextLTPro-It") format("svg"),
    url("../fonts/AvenirNextLTPro-It.ttf") format("truetype"),
    url("../fonts/AvenirNextLTPro-It.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-It.woff2") format("woff2");
}
@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("../fonts/AvenirNextLTPro-Regular.eot");
  src: url("../fonts/AvenirNextLTPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-Regular.otf") format("otf"),
    url("../fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular")
      format("svg"),
    url("../fonts/AvenirNextLTPro-Regular.ttf") format("truetype"),
    url("../fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Righteous";
  src: url("../fonts/Righteous-Regular.ttf");
}
@font-face {
  font-family: "BebasNeue";
  font-weight: 400;
  src: url("../fonts/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf");
}
